import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center vertical"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/75ae1c67ca53d1e176bc24fdc6732102/3ebb1/Morne_the_mage_and_ethereal_rope_made_of_light_coming_from_his__40c7b080-0aa4-4e31-8b27-e583d0f576f0.png",
            "srcSet": ["/static/75ae1c67ca53d1e176bc24fdc6732102/5ff7e/Morne_the_mage_and_ethereal_rope_made_of_light_coming_from_his__40c7b080-0aa4-4e31-8b27-e583d0f576f0.png 375w", "/static/75ae1c67ca53d1e176bc24fdc6732102/3ebb1/Morne_the_mage_and_ethereal_rope_made_of_light_coming_from_his__40c7b080-0aa4-4e31-8b27-e583d0f576f0.png 507w"],
            "width": "400px",
            "className": "right center vertical"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Acronist's Thread`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`All living creatures on Naos are born with a Thread, an invisible, metaphysical strand that connects them to `}<a parentName="p" {...{
        "href": "/The%20Luminary",
        "title": "The Luminary"
      }}>{`The Luminary`}</a>{`. With this thread, sentient beings are able to perform acts of `}<a parentName="p" {...{
        "href": "/Luminous%20Magic",
        "title": "Luminous Magic"
      }}>{`Luminous Magic`}</a>{`. Humans that have their thread severed are known as `}<a parentName="p" {...{
        "href": "/Fade",
        "title": "Fade"
      }}>{`Fades`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      